import React from 'react';

import { l10n, l10nhtml } from '@ott/l10n';

import { LoyaltyMedia } from './blocks/LoyaltyMedia';
import { RewardsMedia } from './blocks/RewardsMedia';
import { SolarCashMedia } from './blocks/SolarCashMedia';

type LoyaltyStage = {
  id: string;
  title: React.ReactNode;
  description: React.ReactNode;
  media: React.ReactNode;
  tagName: string;
  actionName: string;
  actionLink: string;
};

export const LoyaltyStageList: LoyaltyStage[] = [
  {
    id: 'rewards',
    title: l10nhtml('loyaltySection.rewards.title'),
    description: l10nhtml('loyaltySection.rewards.description'),
    media: <LoyaltyMedia />,
    tagName: l10n('loyaltySection.rewards.tagName'),
    actionName: l10n('loyaltySection.rewards.buttonCaption'),
    actionLink: l10n('loyaltySection.rewards.link'),
  },
  {
    id: 'bonuses',
    title: l10nhtml('loyaltySection.bonuses.title'),
    description: l10nhtml('loyaltySection.bonuses.description'),
    media: <SolarCashMedia />,
    tagName: l10n('loyaltySection.bonuses.tagName'),
    actionName: l10n('loyaltySection.bonuses.buttonCaption'),
    actionLink: l10n('loyaltySection.bonuses.link'),
  },
  {
    id: 'referral',
    title: l10nhtml('loyaltySection.referral.title'),
    description: l10nhtml('loyaltySection.referral.description'),
    media: <RewardsMedia />,
    tagName: l10n('loyaltySection.referral.tagName'),
    actionName: l10n('loyaltySection.referral.buttonCaption'),
    actionLink: l10n('loyaltySection.referral.link'),
  },
];
