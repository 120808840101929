import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import cls from 'classnames';

import Button from '@ott/button';

import {
  LoyaltyContent,
  LoyaltyDescription,
  LoyaltyLayout,
  LoyaltyMedia,
  LoyaltySwitchTransition,
  LoyaltyTab,
  LoyaltyTabList,
  LoyaltyTitle,
} from './Loyalty';
import { LoyaltyStageList } from './constants';

import styles from './Loyalty.scss';

const TabProgressDuration = Number(styles.tabProgressDuration);

export const LoyaltySection = () => {
  const [sectionRef, isSectionInView] = useInView({ triggerOnce: true });
  const [isAnimationDisabled, setAnimationDisabled] = useState(true);
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const intervalRef = useRef<number>();

  const CurrentStage = LoyaltyStageList[activeStageIndex];

  const onCompleteHandler = useCallback(() => {
    if (isAnimationDisabled) {
      return;
    }
    setActiveStageIndex((prevState) => (prevState + 1) % LoyaltyStageList.length);
  }, [isAnimationDisabled]);

  const onTabClickHandler = (tabIndex: number) => {
    if (intervalRef.current !== undefined) {
      window.clearTimeout(intervalRef.current);
      intervalRef.current = undefined;
    }

    setActiveStageIndex(tabIndex);
    intervalRef.current = window.setInterval(onCompleteHandler, TabProgressDuration);
  };

  useEffect(() => {
    if (!isAnimationDisabled) {
      intervalRef.current = window.setInterval(onCompleteHandler, TabProgressDuration);
    }
  }, [onCompleteHandler, isAnimationDisabled]);

  /*
     Даем возможность отключить анимацию табов. Используется для снепшот тестов.
  */
  useEffect(() => {
    const loyaltyAnimationDisabled = window.localStorage.getItem('loyaltyAnimationDisabled');

    setAnimationDisabled(Boolean(loyaltyAnimationDisabled) || !isSectionInView);
  }, [isSectionInView]);

  return (
    <section
      ref={sectionRef}
      data-locator="loyalty-program-block"
      className={styles.loyaltySection}
    >
      <LoyaltyLayout>
        <LoyaltySwitchTransition state={activeStageIndex}>
          <LoyaltyMedia>{CurrentStage.media}</LoyaltyMedia>
        </LoyaltySwitchTransition>

        <LoyaltyContent>
          <LoyaltyTabList>
            {LoyaltyStageList.map((stageItem, index) => (
              <LoyaltyTab
                onClick={() => onTabClickHandler(index)}
                onComplete={onCompleteHandler}
                isActive={index === activeStageIndex}
                key={stageItem.id}
                isAnimationDisabled={isAnimationDisabled}
              >
                {stageItem.tagName}
              </LoyaltyTab>
            ))}
          </LoyaltyTabList>

          <LoyaltySwitchTransition state={activeStageIndex}>
            <div className={styles.headingWrapper}>
              <LoyaltyTitle>{CurrentStage.title}</LoyaltyTitle>
              <LoyaltyDescription
                className={cls(CurrentStage.id === 'bonuses' && styles.descriptionBonuses)}
              >
                {CurrentStage.description}
              </LoyaltyDescription>
            </div>
          </LoyaltySwitchTransition>

          <Button
            className={styles.button}
            href={CurrentStage.actionLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LoyaltySwitchTransition state={CurrentStage.actionName}>
              <span>{CurrentStage.actionName}</span>
            </LoyaltySwitchTransition>
          </Button>
        </LoyaltyContent>
      </LoyaltyLayout>
    </section>
  );
};
