import * as React from 'react';
import { useEffect, useImperativeHandle, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import cls from 'classnames';

import styles from './Loyalty.scss';

type LoyaltyProps = {
  children?: React.ReactNode;
};

const LoyaltyLayout = ({ children }: LoyaltyProps) => (
  <div className={styles.container}>{children}</div>
);

const LoyaltyMedia = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={styles.media} {...props}>
      {children}
    </div>
  )
);

LoyaltyMedia.displayName = 'LoyaltyMedia';

const LoyaltyVideo = React.forwardRef<HTMLVideoElement, React.ComponentPropsWithoutRef<'video'>>(
  ({ ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement);

    const onLoadedMetadataHandler = async (event: Event) => {
      const videoElement = event.target as HTMLVideoElement;

      try {
        await videoElement.play();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    useEffect(() => {
      const videoElement = videoRef.current as HTMLVideoElement;

      if (videoElement) {
        /*
          По умолчанию `autoplay=false`, далее руками вызываем метод play у видео.
          Сделано для того, чтобы корректно показывать постер в режиме энергосбережения в IOS,
          без отображения кнопки play (`controls={false}` не убирает эту кнопку почему-то,
          css св-ва для удаления этой кнопки не всегда работали).
        */
        videoElement.addEventListener('loadedmetadata', onLoadedMetadataHandler);
      }

      return () => {
        videoElement.removeEventListener('loadedmetadata', onLoadedMetadataHandler);
      };
    }, []);

    return (
      <video
        ref={videoRef}
        loop={true}
        muted={true}
        playsInline={true}
        controls={false}
        {...props}
        autoPlay={false}
      />
    );
  }
);

LoyaltyVideo.displayName = 'LoyaltyVideo';

type LoyaltyContentProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'>;

const LoyaltyContent = React.forwardRef<HTMLDivElement, LoyaltyContentProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={styles.content} {...props}>
      {children}
    </div>
  )
);

LoyaltyContent.displayName = 'LoyaltyContent';

const LoyaltyTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ children, className, ...props }, ref) => (
    <h2 ref={ref} className={cls(styles.title, className)} {...props}>
      {children}
    </h2>
  )
);

LoyaltyTitle.displayName = 'LoyaltyTitle';

const LoyaltyDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ children, className, ...props }, ref) => (
  <p ref={ref} className={cls(styles.description, className)} {...props}>
    {children}
  </p>
));

LoyaltyDescription.displayName = 'LoyaltyDescription';

const LoyaltyTabList = React.forwardRef<HTMLDivElement, LoyaltyContentProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={styles.tabList} {...props}>
      {children}
    </div>
  )
);

LoyaltyTabList.displayName = 'LoyaltyTabList';

type LoyaltyTabProps = Omit<
  React.HTMLAttributes<HTMLButtonElement>,
  'className' | 'style' | 'type'
> & {
  isActive?: boolean;
  isAnimationDisabled?: boolean;
};

const LoyaltyTab = React.forwardRef<HTMLButtonElement, LoyaltyTabProps>(
  ({ isActive, isAnimationDisabled, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className={cls(
          styles.tab,
          isActive && styles.tabActive,
          isAnimationDisabled && styles.tabWithoutAnimation
        )}
        {...props}
      >
        <span>{children}</span>
      </button>
    );
  }
);

LoyaltyTab.displayName = 'LoyaltyTab';

type LoyaltySwitchTransitionProps = Omit<React.ComponentProps<typeof SwitchTransition>, 'mode'> & {
  state: React.Key;
};

const LoyaltySwitchTransition = ({ children, state, ...props }: LoyaltySwitchTransitionProps) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  return (
    <SwitchTransition {...props}>
      <CSSTransition
        addEndListener={(done) => {
          if (nodeRef.current) {
            nodeRef.current.addEventListener('transitionend', done, false);
          }
        }}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.leave,
          exitActive: styles.leaveActive,
          appear: styles.appear,
          appearActive: styles.appearActive,
        }}
        timeout={Number(styles.fadeDuration)}
        key={state}
        nodeRef={nodeRef}
      >
        <div ref={nodeRef}>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export {
  LoyaltyLayout,
  LoyaltyMedia,
  LoyaltyVideo,
  LoyaltyContent,
  LoyaltyTitle,
  LoyaltyDescription,
  LoyaltyTabList,
  LoyaltyTab,
  LoyaltySwitchTransition,
};
